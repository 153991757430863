/* InvoiceForm.css */

.invoice-form-container {
    margin: 20px;
    font-family: 'Montserrat', sans-serif;
  }
  
  .invoice-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .invoice-header-logo {
    width: 50px;
    height: auto;
  }
  
  .invoice-header-details {
    text-align: right;
    font-size: 12px;
    color: #676868;
  }
  
  .invoice-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  .invoice-table th, 
  .invoice-table td {
    border: 1px solid #c8c8c8;
    padding: 10px;
  }
  
  .invoice-summary {
    margin-bottom: 20px;
    text-align: center;
  }
  

  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    width: 300px;
    text-align: center;
  }

  .custom-input {
    width: 92%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #B0BEC5;
    border-radius: 5px;
    outline: none;
}

.custom-input:focus {
    border-color: #1976d2;
}

.custom-label {
    font-size: 14px;
    font-weight: 500;
    color: #5F6368;
    margin-bottom: 5px;
}

.invoice-label {
  font-size: 14px;
  font-weight: 500;
  color: #5F6368;
  margin-bottom: 5px;
}

.invoice-input {
  width: 8% !important;
  margin-left: 1%;
  margin-top: 1%;
}

table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #ddd;
  border-radius: 10px; /* Borde redondeado en la tabla */
  overflow: hidden; /* Asegura que los bordes redondeados se apliquen bien */
}

th, td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd; /* Bordes sutiles entre filas */
}

th {
  background-color: #f4f4f4; /* Color de fondo sutil para los encabezados */
  font-weight: bold;
}

tr:hover {
  background-color: #f5f5f5; /* Fondo al pasar el ratón sobre una fila */
}

tr:last-child td {
  border-bottom: none; /* Elimina el borde inferior de la última fila */
}

table tr {
  border-bottom: 1px solid #e0e0e0;
}

.input-taxes{
  width: 3% !important;
}

.title-invoice{
  text-align: center;
}

/* Estilos para ocultar y mostrar formularios según el tamaño de la pantalla */
.desktop-only {
  display: block;
}

.mobile-only {
  display: none;
}

@media (max-width: 768px) {
  .desktop-form {
    display: none;
  }
  .mobile-form {
    display: block;
  }
}

@media (min-width: 768px) {
  .desktop-form {
    display: block;
  }
  .mobile-form {
    display: none;
  }
}


/* Modal estilos */
.modal {
  background: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 20px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}

.modal button {
  background: #333;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}

.input-date {
  width: 170px;
  font-family: Montserrat, sans-serif;
}

.input-borrower-name {
  width: 500px;
}

.input-number {
  width: 100px;
}

.input-total {
  width: 120px;
}

.input-error {
  border: 2px solid red;
  animation: blink 1s infinite; /* Animación de titilación */
}
@keyframes blink {
  0%, 50%, 100% {
    border-color: red;
  }
  25%, 75% {
    border-color: transparent;
  }
}
